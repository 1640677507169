@mixin pseudoElement {
	content: '';
	background: red;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	display: inline-block;
}

.article {
	background: var(--backgroundColor);
	color: var(--textColor);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1rem;
	&__content {
		max-width: 80%;
		padding: 16px;
		text-align: left;
	}
	&__header {
		color: darkgreen;
		&::before {
			@include pseudoElement();
		}
		&::after {
			@include pseudoElement();
		}
	}
	&__list {
		list-style-type: circle;
	}
}
