$text-color: blue;
$tablet: 768px;
$desktop: 1280px;

@import 'components/article-grid.scss';
@import 'components/article.scss';
@import 'components/footer.scss';
@import 'components/hamburger.scss';
@import 'components/header.scss';
@import 'components/navigation.scss';
@import 'components/page-header.scss';
@import 'components/paragraph.scss';
@import 'components/section-grid.scss';
@import 'components/sections.scss';
@import 'components/special.scss';
@import '../../node_modules/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
@import 'components/transition.scss';
@import 'components/animations.scss';
:root {
	--textColor: #1a1a1a;
	--backgroundColor: rgb(237, 237, 237);
}

@media (prefers-color-scheme: dark) {
	:root {
		--textColor: rgb(237, 237, 237);
		--backgroundColor: #1a1a1a;
	}
}

html {
	block-size: border-box;
}

*,
*:before,
*:after {
	block-size: inherit;
}

body {
	background: #0093e9;
	background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
	font-family: 'Rubik', sans-serif;
	@media screen and (min-width: $tablet) {
		background: white;
	}
}

main {
	max-width: $tablet;
	margin: 0 auto;
}

table {
	padding: 16px;
	width: 100%;
	text-align: center;
	caption {
		margin-bottom: 2rem;
	}
}
