.footer {
	padding: 16px;
	max-width: 768px;
	margin: 0 auto;
	background-color: #1460aa;
	&__content {
		color: #d35050;
	}
	&__top-link {
		display: block;
		position: fixed;
		bottom: 16px;
		right: 16px;
		color: white;
		padding: 8px 12px;
		background-color: black;
		border-radius: 8px;
	}
}
