// WTF 10.10 CSS: animation
@keyframes wave {
	0% {
		transform: rotate(-30deg);
	}
	50% {
		transform: rotate(60deg);
	}
	100% {
		transform: rotate(30deg);
	}
}

.wave {
	font-size: 3rem;
	display: inline-block;
	transform: rotate(-30deg);
	transform-origin: bottom center;
	animation: wave ease-in-out 2s infinite alternate forwards;
}
