.header {
	min-height: 50px;
	background-color: #150a1e;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@media screen and (min-width: $tablet) {
		position: sticky;
		top: 0;
	}
}
