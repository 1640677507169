.navigation {
	display: none;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	gap: 24px;
	background-color: #150a1e;
	padding: 16px;
	position: absolute;
	top: 50px;
	width: 100%;
	&--open {
		display: flex;
	}
	&__link {
		color: white;
	}
	@media screen and (min-width: $tablet) {
		display: flex;
		flex-flow: row wrap;
		position: static;
	}
}
