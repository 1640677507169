.paragraph {
	background-color: blue;
	padding: 4px;
	color: white;
	text-align: center;
	&__link {
		color: tomato;
		&:hover {
			color: yellow;
		}
	}
	&__item {
		&--special {
			color: #a20041;
		}
		&:not(.paragraph__item--special) {
			color: greenyellow;
		}
		&:first-child {
			color: #0093e9;
		}
		&:last-child {
			color: #80d0c7;
		}
	}
	&:hover {
		background: #333;
	}
	&::before {
		content: 'Hello!';
		color: #6b5858;
		display: block;
	}
	&::after {
		content: '';
		display: block;
		background: #6b5858;
		width: 100px;
		height: 100px;
		border-radius: 100px;
	}
}
